body#survey_steps { padding: 0; }

html {

  body {
    padding-top: $account-nav-height-desktop;

    &.noUserBar { padding-top: 0; }
  }

  &.has-mobile-nav {
    body {
      padding-top: $account-nav-height-mobile;
    }

    // position relative to avoid conflicts with the smart app banner (adds html margin)
    #accountNav {
      position: relative;
      margin-top: -$account-nav-height-mobile;
    }
  }

  &.page-manager-visible {
    body { padding-left: $page-manager-width; }
    // #topNav { margin-left: $page-manager-width; }
  }

  // &.nav-fixed {
  //   .site-background {
  //     margin-top: $nav-placeholder-height-fixed - $nav-placeholder-height;
  //     bottom: $nav-placeholder-height-fixed - $nav-placeholder-height;
  //   }
  // }

  // &:not(.collapsed-mobile-nav) {
  //
  //   .site-background {
  //     transition: margin-top $transition-linear, bottom $transition-linear;
  //   }
  //
  //   &:not(.has-sub-nav) {
  //     .site-background { margin-top: -$sub-nav-height; }
  //
  //     &.nav-fixed {
  //       .site-background {
  //         margin-top: $nav-placeholder-height-fixed - $nav-placeholder-height - $sub-nav-height-fixed;
  //       }
  //     }
  //   }
  // }

  // &.collapsed-mobile-nav {
  //   .site-background { margin-top: -$nav-placeholder-height; }
  // }
}
