body{
  display: flex;
  flex-flow: column;
}
html:not(.collapsed-mobile-nav):not(.has-sub-nav) .site-background{
  margin-top: 0px;
}
@media screen and (max-width: 1024px){
  html .site-background{
    background-size: cover, 15px 15px, cover;
  }
}
.site-background{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: url(../images/usayess/brushed-metal-bg.png), url(../images/usayess/dot.svg), linear-gradient(to right, #092e5b, #1461BB, #092e5b);
  background-position: center 50px, center, center;
  background-repeat: no-repeat, repeat, no-repeat;
  background-size: auto, 15px 15px, cover;
  .page-manager-visible &{
    left:60px;
  }
  .has-mobile-nav &{
    background-position: center 2%, center, center;
  }
  @media screen and (min-width: 1600px){
    background-size: contain, 15px 15px, cover;
  }

}

#siteContainer{
  padding: 10px;
  background: #e1e1e1;
  box-shadow: 0 4px 10px #000;
}
#yieldContent{
  background-color: #FFF;
  box-shadow: 0 0 15px rgba(0,0,0,.15);
}
html.collapsed-mobile-nav.page-manager-visible #topNav{
  left: 60px;
}
#panelTwo{
    padding-top: 35px;
    padding-bottom: 35px;
}
#banner-logos{
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  @media screen and(max-width: 650px){
    flex-flow: row wrap;
  }
  .pageEl{
    width:100%;
    display: block;
    margin-top: auto;
    .pageElement{
      margin: 0px;
      img{
        border: 0px;
      }
    }
    @media screen and(max-width: 768px){
      margin: auto;
    }
    .has-mobile-nav & {
      padding: 10px;
    }
    &.banner-logo-middle{
      max-width: 200px;
      img{
        max-height:150px;
        width:auto;
      }
      @media screen and(min-width: 836px){
        margin: 0 20px 0 10px;
      }
    }
    &.banner-logo-left{max-width: 143px; margin-left:20px;}
    &.banner-logo-right{max-width: 116px; margin-right:20px;}
    &:not(.banner-logo-middle){
      @media screen and(max-width: 835px){
        display: none;
      }
    }
  }
}
//
#banner-buttons{
  display: none;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  #topNavPlaceholder &{
    display:inline-block;
  }
  .banner-button{
    margin-left: 10px;
    float: left;
    display: inline-block;
    .pageElement{
      margin: 0px;
    }
  }
  @media screen and(max-width: 651px){
    position: relative;
    margin: auto;
    display: block;
    width: 100%;
    .banner-button{
      float: none;
    }
  }
  a{
    text-decoration: none;
    display: inline-block;
    background: linear-gradient(to bottom, #a90329 0%,#c32b30 48%,#b00610 49%,#6d0019 100%);
    border-radius: 3px;
    color: #fff;
    font-family: verdana,sans-serif;
    font-weight: bold;
    font-size: 15px;
    line-height: 1;
    padding: 5px 15px;
    text-shadow: -1px -1px 0 #000;
    text-transform: uppercase;
    border-left: 1px solid #cb797c;
    border-top: 1px solid #cb797c;
    box-shadow: 1px 1px 1px #000;
    &:after{
      display: none;
    }
    &:hover{opacity: 0.8;}
    .has-mobile-nav &{
      margin-top: 10px;
    }
  }
}
